import { css } from "styled-components";
import Fonts from './Fonts';
import Colors from "./Colors";
import { tablet } from "./Responsive";

const Typography = {
  richText: css`
    margin-top: 60px;
    font-size: 14px;
    line-height: 15.4px;
    font-family: ${Fonts.IBMRegular};

    p {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: ${tablet}) {
      margin-top: 30px;
      font-size: 12px;
      line-height: 13.2px;

      p {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    }
  `,
  underlineLink: css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: ${Colors.green};
      transition: opacity .2s, transform .2s;
      opacity: 0;
      transform: scale(0);
      transform-origin: center;
    }

    &:hover:after,
    &:focus:after{
      opacity: 1;
      transform: scale(1);
    }
  `,
}

export default Typography;
