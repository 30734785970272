import { Link } from 'gatsby';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';
import Fonts from '../../../assets/styles/Fonts';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";
// UI
import H1 from '../../UI/Titles/H1';
import H4 from '../../UI/Titles/H4';
import Carousel from '../../UI/Carousel';

const AgeCheckStyles = styled.div`
    .mymodal_cover{
        display: ${({ ageGood }) => (ageGood) ?  'none' : 'block'};
        z-index: 5000;
        background: black;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: fixed;
    }

    .mymodal{
        z-index: 6000;
        opacity: 1;
        position: fixed;
        top: calc(50% - 200px);
        left: calc(50% - 440px);
        width: 800px;
        min-height: 440px;
        background-color: white;
        border-radius: 5px;

        flex-direction: row;
        justify-content: center;
        display: ${({ ageGood }) => (ageGood) ?  'none' : 'flex'};

        h1{
            color: black;
        }

    }

    @media screen and (max-width: 875px){
        .mymodal{
            width: 500px;
            min-height: 300px;
            top: calc(50% - 150px);
            left: calc(50% - 250px);
        }
    }
    @media screen and (max-width: 450px){
        .mymodal{
            width: 90%;
            min-height: 310px;
            top: calc(50% - 155px);
            left: calc(50% - 45%);
        }
    }

    .modal_content{
        display: inline-block;
        text-align: center;
        margin: 50px;
    }
    @media screen and (max-width: 450px){
        .modal_content{
            margin: 25px;
            max-width: 300px;
            max-height: 300px;
        }
    }

        
    .sub_text{
        font-size: 25px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 0px 185px;
    }
    #FailText{
        display: none;
    }
    @media screen and (max-width: 875px){
        .sub_text{
            font-size: 15px;
            margin-top: 30px;
            margin-bottom: 30px;
            padding: 0px 100px;
        }
    }
    @media screen and (max-width: 450px){
        .sub_text{
            font-size: 15px;
            margin-top: 25px;
            margin-bottom: 25px;
            padding: 0px 50px;
        }
    }

    .modal_buttons{
        margin-top: 0px;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        width: 100%;
    }

    .modal_buttons button{
        margin: 0px 20px;
        padding-left: 60px;
        padding-right: 60px;
        border-radius: 10px;
        color: white;
        background-color: var(--darkgreen);
        padding: 12px 30px;
        
    }

    @media screen and (max-width: 450px){
        .modal_buttons{
            padding: 8px 10px;
        }
        .modal_buttons button{
            padding: 10px 40px;
        }
    }
    button{
        cursor: pointer;
    }
    #yes_btn{
        display: ${({ failState }) => (failState) ?  'none' : 'block'};
    }
    #no_btn{
        display: ${({ failState }) => (failState) ?  'none' : 'block'};
    }
    #DefaultText{
        display: ${({ failState }) => (failState) ?  'none' : 'block'};
    }
    #FailText{
        display: ${({ failState }) => (failState) ?  'block' : 'none'};
    }

    .modal_text .title{
        font-family: 'Druk Wide Medium';
        font-size: 5vh;
    }

`;

export {
    AgeCheckStyles,
}