const Colors = {
  green: '#446961',
  greenLight: '#D6E2DD',
  shadowGreen: '#A2C7B3',
  white: '#fff',
  gin: '#E3EFEA',
  aqua: '#E7F8F4',
  silver: '#C4C4C4',
  mineralGreen: '#3F615A',
}

export default Colors;