import React from 'react';
import { CheckoutContext } from '../../contexts/checkout-context';
import useDutchieCheckout from '../../hooks/useDutchieCheckout';


const DutchieContextProvider = ({ children }) => {
  const {
    checkoutId,
    checkout,
    checkoutLoading,
    checkoutError,
    setOrderType,
    setPricingType,
    pricingType,
    orderType,
    addToCart,
    removeFromCart,
    updateQuantity,
    activeCategory,
    activeSubCategory,
    setActiveCategory,
    setActiveSubCategory,
    retailerList,
    currentRetailer,
    setCurrentRetailer,
  } = useDutchieCheckout(OrderTypes.Pickup, PricingTypes.Recreational);

  return (
    <CheckoutContext.Provider
      value={{
        pricingType,
        orderType,
        activeCategory,
        activeSubCategory,
        checkoutId,
        checkout,
        checkoutError,
        loading: checkoutLoading,
        setOrderType,
        setPricingType,
        setActiveCategory,
        setActiveSubCategory,
        addToCart,
        removeFromCart,
        updateQuantity,
        retailerList,
        currentRetailer,
        setCurrentRetailer
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const Categories = [
  'ACCESSORIES',
  'APPAREL',
  'CBD',
  'CLONES',
  'CONCENTRATES',
  'EDIBLES',
  'FLOWER',
  'NOT_APPLICABLE',
  'PRE_ROLLS',
  'SEEDS',
  'TINCTURES',
  'TOPICALS',
  'VAPORIZERS',
];

export const SubCategories = [
  'SINGLES',
  'PACKS',
  'INFUSED',
  'BLUNTS',
  'CARTRIDGES',
  'PODS',
  'DISPOSABLES',
  'BUNDLES',
  'SHATTER',
  'LIVE_RESIN',
  'WAX',
  'ROSIN',
  'KIEF',
  'BUDDER',
  'CRUMBLE',
  'RSO',
  'APPLICATORS',
  'SUGAR',
  'SAUCE',
  'DIAMONDS',
  'ISOLATE',
  'INFUSED_FLOWER',
  'HASH',
  'BADDER',
  'OIL',
  'CHOCOLATES',
  'GUMMIES',
];

export const parseCategory = (categoryName) => {
  const overrides = { VAPES: 'VAPORIZERS', CATRIDGES: 'CARTRIDGES' };
  const result = categoryName.replace('-', '_').replace(' ', '_').replace('/', '_').toUpperCase();
  if (overrides.hasOwnProperty(result)) return overrides[result];
  if (Categories.includes(result) || SubCategories.includes(result)) return result;
  else // console.log('Error: Unmapped Category Name: ' + result);
  return;
};

export const OrderTypes = {
  Delivery: 'DELIVERY',
  Pickup: 'PICKUP',
};

export const PricingTypes = {
  Medical: 'MEDICAL',
  Recreational: 'RECREATIONAL',
};

export default DutchieContextProvider;
