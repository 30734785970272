import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import useLocalStorage from './useLocalStorage';

import RETAILER_LIST_QUERY from '../services/dutchie/queries/retailerlist.graphql';
import CHECKOUT_QUERY from '../services/dutchie/queries/checkout.graphql';
import CREATE_CHECKOUT from '../services/dutchie/mutations/create-checkout.graphql';
import ADD_ITEM_TO_CHECKOUT from '../services/dutchie/mutations/add-item-to-checkout.graphql';
import REMOVE_ITEM_FROM_CHECKOUT from '../services/dutchie/mutations/remove-item-from-checkout.graphql';
import UPDATE_CHECKOUT_ITEM_QUANTITY from '../services/dutchie/mutations/update-checkout-item-quantity.graphql';
import UPDATE_CHECKOUT from '../services/dutchie/mutations/update-checkout.graphql';
import { toast } from "react-toastify"
import { showErrorToast } from "../utils/customErrorToast"

const useCheckout = (initialOrderType, initialPricingType) => {
  // Local Storage for Checkout ID
  const [checkoutId, setCheckoutId] = useLocalStorage('dutchie-plus--checkout-id');
  const [retailerList, setRetailerList] = useState();
  const [currentRetailer, setCurrentRetailer] = useLocalStorage('dutchie-plus--retailer-id');

  // Queries / Mutations
  const {
    data: retailerData, 
    loading: retailerLoading, 
    error: retailerError
  } = useQuery(RETAILER_LIST_QUERY);
  
  const {
    data: checkoutData,
    loading: checkoutLoading,
    error: checkoutError,
    refetch: refetchCheckout,
  } = useQuery(CHECKOUT_QUERY, {
    variables: { id: checkoutId, retailerId: currentRetailer },
    fetchPolicy: "standby",   // Used for first execution
    nextFetchPolicy: "cache-first" // Used for subsequent executions
  });

  
  const [
    createCheckout,
    { data: createCheckoutData, loading: createCheckoutLoading, error: createCheckoutError },
  ] = useMutation(CREATE_CHECKOUT);
  const [
    updateCheckout,
    { data: updateCheckoutData, loading: updateCheckoutLoading, error: updateCheckoutError },
  ] = useMutation(UPDATE_CHECKOUT);
  const [
    addItemToCheckout,
    { data: addToCartData, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(ADD_ITEM_TO_CHECKOUT);
  const [
    removeItemFromCheckout,
    { data: removeFromCartData, loading: removeFromCartLoading, error: removeFromCartError },
  ] = useMutation(REMOVE_ITEM_FROM_CHECKOUT);
  const [
    updateCheckoutItemQuantity,
    { data: updateQuantityData, loading: updateQuantityLoading, error: updateQuantityError },
  ] = useMutation(UPDATE_CHECKOUT_ITEM_QUANTITY);

  // STATE TRACKING
  const [checkout, setCheckout] = useState();
  const [orderType, setOrderType] = useState(initialOrderType);
  const [pricingType, setPricingType] = useState(initialPricingType);
  //for Slider Filter re-render issue...
  const [activeCategory, setActiveCategory] = useState('all');
  const [activeSubCategory, setActiveSubCategory] = useState('all');

  //fetch cart items after update page
  useEffect(() => {
    updateCheckoutAsync(orderType, pricingType)
  }, [])

  // Set Retailer List
  useEffect(() => {
    // console.log("setRetailerList UseEffect")
    if (retailerList === undefined || retailerList === null || retailerList === []){
      if (!retailerLoading && retailerData) {
        // console.log("   setting retailer list...(calling setRetailerList)")
        // createRetailerListAsync(retailerData.retailers)
        createRetailerListAsync(retailerData.retailers);
        // console.log(retailerData)
        // console.log(retailerData.retailers)
        // console.log("   ...done! (printing retailerList bellow)");
        // console.log(retailerList);
      }else{
        // console.log("   setRetailerList Failed (else branch hit)")
        // console.log("     retailerLoading->"+retailerLoading);
        // console.log("     retailerData:");
        // console.log(retailerData);
      }
    }
  }, [retailerLoading, retailerData]);

  // Create a new retailerList if error happened
  useEffect(() => {
    // console.log("RetailerListError UseEffect")
    // if(retailerError) console.log(" retailerError--->"+retailerError)
  }, [retailerError])

  // Create retailer List is none exists
  // useEffect(() => {
  //   if (retailerList === undefined || retailerList === null || retailerList === [])
  //     createRetailerListAsync()
  // },[])

  // Create a checkout if none exists
  useEffect(() => {
    // console.log("createCheckout useEffect, [currentRetailer--->["+currentRetailer+"]")
    if(currentRetailer){
      refetchCheckout()
      if (checkoutId === undefined || checkoutId === null || checkoutId === '')
        createCheckoutAsync(orderType, pricingType, currentRetailer);
    }
  }, [currentRetailer]);

  // Set checkout data
  useEffect(() => {
    // console.log("setCheckout useEffect")
    if(currentRetailer){
      if (!checkoutLoading && checkoutData) {
        setCheckout(checkoutData.checkout);
        setPricingType(checkoutData.checkout.pricingType);
        setOrderType(checkoutData.checkout.orderType);
      }
    }
  }, [checkoutLoading, checkoutData, currentRetailer]);

  // If loading the checkout failed, create a new checkout
  useEffect(() => {
    // console.log("CheckoutError UseEffect")
    if (checkoutError){
      // console.log(" checkoutError-->"+checkoutError)
      createCheckoutAsync(orderType, pricingType, currentRetailer);
      refetchCheckout();
    }
  }, [checkoutError]);

  // Update checkout if Order or Pricing Type changes
  useEffect(() => {
    // console.log("UseEffect  [Update checkout if Order or Pricing Type changes]")
    if (checkout && (checkout.orderType !== orderType || checkout.pricingType !== pricingType))
      updateCheckoutAsync(orderType, pricingType);
  }, [orderType, pricingType]);

  const updateStateFromCheckout = (checkout) => {
    setCheckoutId(checkout.id);
    setCheckout(checkout);
    setPricingType(checkout.pricingType);
    setOrderType(checkout.orderType);
  };

  // Crate a retailer list asynchronously
  const createRetailerListAsync = async (retailers) => {
    // console.log("         start createRetailerListAsync...")
    try {
      setRetailerList(retailers);
      // console.log("             retailerList print...")
      // console.log(retailers)
      if(currentRetailer===undefined || currentRetailer===null || currentRetailer==''){
        setCurrentRetailer(retailers[0].id)
      }
    } catch (e) {
      // console.error(e);
      return undefined;
    }
    // console.log("       ...end createRetailerListAsync")
  }

  // Set a Current Retailer
  // const setCurrentRetailerAsync = async (currentRetailer) => {
  //   // console.log("   start createCurrentRetailerAsync...")
  //   try {
  //     // console.log("         currentRetailer print...")
  //     // console.log(currentRetailer)
  //     setCurrentRetailer(currentRetailer.id);
  //   } catch (e) {
  //     console.error(e)
  //     return undefined
  //   }
  //   // console.log("   ...end createCurrentRetailerAsync")
  // }

  // Create a checkout asynchronously
  const createCheckoutAsync = async (orderType, pricingType, currentRetailer) => {
    try {
      const result = await createCheckout({
        variables: { orderType, pricingType, retailerId: currentRetailer },
      });
      updateStateFromCheckout(result.data.createCheckout);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const updateCheckoutAsync = async (orderType, pricingType) => {
    try {
      const result = await updateCheckout({
        variables: { checkoutId, orderType, pricingType, retailerId: currentRetailer },
      });
      setCheckoutId(result.data.updateCheckout.id);
      setCheckout(result.data.updateCheckout);

      return result.data.updateCheckout;
    } catch (e) {
      // console.log(e);
      return undefined;
    }
  };

  useEffect(() => {
    if(addToCartError) {
      if(addToCartError.message === 'Validation errors') {
        showErrorToast("Total exceeds available quantity")
      }
      else {
        showErrorToast(addToCartError.message)
      }
    }
  }, [addToCartError])


  // Wrapper Functions for Error Handling
  const addToCart = async (productId, quantity, option) => {

    try {
      const result = await addItemToCheckout({
        variables: {  retailerId: currentRetailer, checkoutId, productId, quantity, option },
      });

      updateStateFromCheckout(result.data.addItem);

      toast("Added Item To Cart!", {
        position: "bottom-right",
        autoClose: 5000,
        newestOnTop: false,
        closeOnClick: true,
        pauseOnHover: false
      })
      
      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const removeFromCart = async (itemId) => {
    try {
      const result = await removeItemFromCheckout({
        variables: { checkoutId, itemId, retailerId: currentRetailer },
      });

      updateStateFromCheckout(result.data.removeItem);
      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    try {
      const result = await updateCheckoutItemQuantity({
        variables: { checkoutId, itemId, quantity, retailerId: currentRetailer },
      });
      // console.log("updateQuantity result.data.updateItem:")
      console.log(result.data.updateItem)
      // console.log("updateQuantity result.data:")
      console.log(result.data)
      updateStateFromCheckout(result.data.updateQuantity);
      return checkout;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  return {
    setOrderType,
    setPricingType,
    setActiveCategory,
    setActiveSubCategory,
    setCurrentRetailer,
    orderType,
    pricingType,
    activeCategory,
    activeSubCategory,
    checkoutId,
    checkout,
    checkoutLoading,
    checkoutError,
    currentRetailer,
    retailerList,

    // Functions
    createCheckoutAsync,
    createRetailerListAsync,
    updateCheckoutAsync,
    addToCart,
    removeFromCart,
    updateQuantity,
  };
};

export default useCheckout;
