import { createGlobalStyle } from 'styled-components';

import { MOBILE_BREAKPOINT } from './breakpoints';

const GlobalStyles = createGlobalStyle`
  :root {
    --green: #A2C7B3;
    --darkgreen: #446961;
    --darkergreen: #2C443F;
    --darkestgreen: #233531;
    --lightgreen: #E3EFEA;
  }
  
  body, html {
    margin: 0;
    padding: 0;

    overflow-y: auto;


    scrollbar-width: thin;
    scrollbar-color: #A2C7B3 #C3D4CF;
    &::-webkit-scrollbar {
        width: 7px;
        background: #C3D4CF;
        //border-radius: 5px;
        transition: 250ms;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #A2C7B3;
        //border-radius: 10px;

        &:hover{
            background-color: #89a194;
        }

        &:active{
            background-color: #59806b;
        }
    }
  }
  ::-moz-scrollbar-button:decrement,
  ::-moz-scrollbar-button:increment,
  ::-webkit-scrollbar-button:decrement,
  ::-webkit-scrollbar-button:increment {
    width: 0px;
  }
  
  a{
      color: inherit;
      text-decoration: none;
  }

    .Toastify__toast {
        background: var(--green);
        border-radius: 0;
    }

    .Toastify__toast-body{
        color: var(--darkgreen);
        font-family: IBM Plex Mono Bold;
    }

    .Toastify__close-button{
        color: var(--darkgreen);
        opacity: 1;
    }

    .Toastify__progress-bar, .Toastify__progress-bar-theme--light, .Toastify__progress-bar-theme--dark {
        background: var(--darkgreen);
    }

    @media(max-width: ${MOBILE_BREAKPOINT}){
        .Toastify__toast {
            height: 100px;
        }

        .Toastify__toast {
            font-size: 24px;
        }

        .Toastify__close-button > svg {
            height: 30px;
            width: 30px;
        }

        .Toastify__toast-body > div:last-child {
            flex: none;
        }
    }
  .react-select-filter {	  
	  &__single-value {
		  color: var(--darkgreen)!important;
	  }
	  &__control {
		  background-color: transparent!important;
      border: 1px solid rgba(177,178,179,0.5)!important;
	  }
	  
	  &__menu {
		  
	  }
  }
  
  .customCheckbox {
    width: 86px!important;
    height: 33px!important;
	  margin: 0!important;
	  
	  input {
		  &:checked {
			  + .state {
				  label {
					  background: #A0C0B0;
				  }
			  }
		  }
	  }
	  .state {
		  width: 100%;
		  height: 100%;
      label {
	      border: 1px solid #446961;
        text-indent: .5em!important;
        width: 100%;
	      height: 100%;
        display: flex!important;
	      justify-content: flex-start!important;
	      align-items: center!important;
        &::before, &::after {
          display: none!important;
        }
      }
	  }
  }
  .custom-error-toast {
	  background: tomato;

    .Toastify {
	    &__toast-body, &__close-button {
		    color: white;
	    }
	    
	    &__toast-icon {
		    svg {
			    fill: white;
		    }
	    }
	    &__progress-bar {
		    background: white;
	    }
    }
  }
`;

export default GlobalStyles;
