// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-templates-article-index-jsx": () => import("./../../../src/components/templates/Article/index.jsx" /* webpackChunkName: "component---src-components-templates-article-index-jsx" */),
  "component---src-components-templates-categories-categories-js": () => import("./../../../src/components/templates/Categories/categories.js" /* webpackChunkName: "component---src-components-templates-categories-categories-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mainmenu-js": () => import("./../../../src/pages/mainmenu.js" /* webpackChunkName: "component---src-pages-mainmenu-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-near-me-js": () => import("./../../../src/pages/near-me.js" /* webpackChunkName: "component---src-pages-near-me-js" */),
  "component---src-pages-new-products-[id]-js": () => import("./../../../src/pages/new-products/[id].js" /* webpackChunkName: "component---src-pages-new-products-[id]-js" */),
  "component---src-pages-producttemplate-js": () => import("./../../../src/pages/producttemplate.js" /* webpackChunkName: "component---src-pages-producttemplate-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-template-product-js": () => import("./../../../src/template/product.js" /* webpackChunkName: "component---src-template-product-js" */)
}

