module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NW6GL5D","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://plus.dutchie.com/plus/2021-07/graphql","headers":{"Authorization":"Bearer public-eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJBUEktQ0xJRU5UIiwiZXhwIjozMzE5MTE0MjA5OSwiaWF0IjoxNjM0MjMzMjk5LCJpc3MiOiJodHRwczovL2R1dGNoaWUuY29tIiwianRpIjoiMzhjMzIwNTEtMWRiNy00OWMxLWJhYzktZTNjNzY4MjNiYzA4IiwiZW50ZXJwcmlzZV9pZCI6ImYxOWE2OGJlLTI4NmQtNGUzZS1iOTdiLTAzNjIyNjU0NjBiMSIsInV1aWQiOiJhM2NiZDIwYy1iYThmLTQ4MWUtOTc2Yi02NzlhZjVkMDJjMTkifQ.SALIhhBJoL7J4wHydiB089heHULn2r8RybuBUGDBgt0"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Druk Wide Bold, Druk Wide Medium, IBM Plex Mono Bold, IBM Plex Mono Medium, IBM Plex Mono Regular, IBM Plex Mono SemiBold"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
