import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Fonts
import Fonts from '../../../../assets/styles/Fonts';
import { tablet } from "../../../../assets/styles/Responsive";

const Element = styled.h4`
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  font-family: ${Fonts.DrukBold};

  @media (max-width: ${tablet}) {
    font-size: 16px;
    line-height: 18px;
  }
`;

const H4 = ({
  value, className, onClick = () => {},
}) => (
  <Element className={className} onClick={onClick}>
    {value}
  </Element>
);

H4.defaultProps = {
  value: '',
};

H4.propTypes = {
  value: PropTypes.string,
};

export default H4;
