import React, { useState, useEffect } from 'react';
import {
    AgeCheckStyles,
} from './styled';

import Cookies from 'universal-cookie'

export default function AgeGate(){
    const cookies = new Cookies();
    const isAnAdult = cookies.get('isAnAdult');

    const [failState, setFailState] = useState(false);  
    const [ageGood, setAgeGood] = useState(true); 
    

    useEffect(() => {
        setAgeGood(isAnAdult);
    }, []);
    

    function ageCheck(e) {
    
        //----------Set-up Date
        var today = new Date;
        var days = 14; //number of days until they must go through the age checker again.
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        
        //--------Hide Elements
        setAgeGood(true);
    
        //--------Set Cookie
        cookies.set('isAnAdult', true, { path: '/', expires: date });
    
    };
    
    function ageFail(e) {
        setFailState(true);
        setAgeGood(false);
    }

    if (ageGood=='true'){
        return(
            <div></div>
        );
    }
    return(
        <AgeCheckStyles ageGood={ageGood} failState={failState}>
            <div id="AgeVerifCover" className="mymodal_cover">
            </div>
            <div id="AgeVerif" className="mymodal">
                    <div className="modal_content">
                        <div className="modal_text">
                            <div className="title"> Are you over 19?</div>
                            <div className="sub_text">
                                <div id="DefaultText">
                                    Please verify that you are 19 years of age or older to enter the site
                                </div>
                                <div id="FailText">
                                    Sorry, come back when you're 19.
                                </div>
                            </div>
                        </div>
                        <div className="modal_buttons">
                            <button id="yes_btn" onClick={ageCheck} className="hoc-button-outline">YES</button>
                            <button id="no_btn" onClick={ageFail} className="hoc-button-outline">NO</button>
                        </div>
                    </div>
            </div>
        </AgeCheckStyles>
    )
}