import { toast } from "react-toastify"

export const showErrorToast = (message) => {
	toast.error(message, {
		position: "bottom-right",
		autoClose: 5000,
		newestOnTop: false,
		closeOnClick: true,
		pauseOnHover: true,
		toastId: "error-toast",
		className: "custom-error-toast"
	});
};