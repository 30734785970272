import React from 'react';

import AgeGate from "./src/components/templates/AgeGate";

//Context Vars
import { default as DutchieContextProvider } from "./src/services/dutchie"

//Styles
import "rc-pagination/assets/index.css";
import GlobalStyles from './src/styles/GlobalStyles';
import 'swiper/swiper-bundle.min.css';
import '@djthoms/pretty-checkbox';
import 'react-toastify/dist/ReactToastify.css';

export function wrapPageElement({ element, props }) {
  return (
    <>
        <GlobalStyles/>
        <AgeGate />
        <DutchieContextProvider>
          {element}
        </DutchieContextProvider>
    </>
  );
}
