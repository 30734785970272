import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
// Style
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({
  className, settings: stgs, children,
  carouselRef, onChange, id,
}) => {
  const settings = stgs;

  return (
    <div className={className} id={id}>
      <Slider {...settings} ref={carouselRef} onInit={() => {}} afterChange={(index) => onChange(index)}>
        {children}
      </Slider>
    </div>
  );
};

Carousel.defaultProps = {
  settings: {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  },
  onChange: () => {},
};

Carousel.propTypes = {
  settings: PropTypes.shape({
    dots: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  carouselRef: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
};

export default Carousel;
